<template>
  <v-container>
    <row>
      <v-col>
        <v-card
            class="mx-auto"
            max-width="344"
        >
          <v-card-text>
            <div>Fruit</div>
            <p class="text-h4 text--primary">
              Fruits
            </p>
            <div>
              <v-text-field
                  v-model="collaborateur"
                  label="Nombre de collaborateurs"
                  :rules="rules"
                  hide-details="auto"
              ></v-text-field>

              <v-select
                  v-model="frequence"
                  :items="frequences"
                  label="Fréquence"
                  @change="nbreBox"
              ></v-select>
            </div>
          </v-card-text>
          <v-card-actions>
            <h5> Composition </h5>
          </v-card-actions>
          <v-card-actions>
            <p> Soit {{ nbre3kg }} une cagette de 3/4kg </p>
          </v-card-actions>
          <v-card-actions>
            <p> Soit {{ nbre6kg }} une cagette de 6/7kg </p>
          </v-card-actions>
          <v-card-actions>
            <p> Soit {{ nbre10kg }} une cagette de 10/12kg </p>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <p> Total {{ calculFruit }} € </p>
          </v-card-actions>
        </v-card>
      </v-col>
    </row>
  </v-container>
</template>

<script>
export default {
  name: "CalculatorFruit",
  data: () => ({
    rules: [
      value => !!value || 'Required.',
    ],
    frequences: [
      "Toutes les semaines",
      "Toutes les deux semaines",
      "1 fois par mois",
    ],
    frequence: 0,
    freq1: 4.33,
    freq2: 2.17,
    freq3: 1,
    collaborateur: null,
    consoMoy:10,
    poidsMoy:0.007,
    poids: 0,
    prix3kg:19.9,
    prix6kg:29,
    prix10kg:45,
    box015: 0,
    box1525:0,
    box2545:0,
  }),
  computed:{
    // nbre3kg() {
    //   return Math.round(((this.collaborateur%45)%25)/15)
    // },
    // nbre6kg(){
    //   return Math.round((this.collaborateur%45)/25)
    // },
    // nbre10kg() {
    //   return Math.trunc(this.collaborateur/45)
    // },
    nbre3kg() {
      return Math.round(((this.collaborateur%45)%25)/15)
    },
    nbre6kg(){
      return Math.trunc((this.collaborateur%45)/25)
    },
    nbre10kg() {
      return Math.trunc(this.collaborateur/45)
    },

    collab015(){
      if (this.collaborateur>0 && this.collaborateur<16){
        return this.nbre3kg
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box015 = 0
      }
      return this.box015
    },
    collab1525(){
      if (this.collaborateur>15 && this.collaborateur<26){
        console.log(25)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box1525 = 1
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box1525 = 0
      }
      return this.box1525
    },
    collab2545(){
      if (this.collaborateur>25 && this.collaborateur<46){
        console.log(45)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box2545 = 1
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box2545 = 0
      }
      return this.box2545
    },
    collab45plus(){
      if (this.collaborateur>45){
        console.log(45)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box2545 = Math.round(this.collaborateur/45)
        if(this.collaborateur%45 > 25 && (this.collaborateur%45)<46 ){
          this.collab2545()
          console.log(this.box2545)
        }
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.box2545 = 0
      }
      return this.box2545
    },
    // eslint-disable-next-line vue/return-in-computed-property
    calculFruit(){
      if(this.collaborateur>45){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.prix6kg = 26.59
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.prix3kg = 14.32
        if(this.frequence === "Toutes les semaines"){
          return  (((this.nbre3kg*this.prix3kg)+(this.nbre6kg*this.prix6kg)+(this.nbre10kg*this.prix10kg))*this.freq1).toFixed(2)
        } else if (this.frequence === "Toutes les deux semaines"){
          return  (((this.nbre3kg*this.prix3kg)+(this.nbre6kg*this.prix6kg)+(this.nbre10kg*this.prix10kg))*this.freq2).toFixed(2)
        }else if (this.frequence === "1 fois par mois"){
          return  (((this.nbre3kg*this.prix3kg)+(this.nbre6kg*this.prix6kg)+(this.nbre10kg*this.prix10kg))*this.freq3).toFixed(2)
        } else {
          return "Erreur: Aucune Fréquence valable trouvé"
        }
      }else {
        if(this.frequence === "Toutes les semaines"){
          return (((this.nbre3kg*this.prix3kg)+(this.nbre6kg*this.prix6kg)+(this.nbre10kg*this.prix10kg))*this.freq1).toFixed(2)
        } else if (this.frequence === "Toutes les deux semaines"){
          return (((this.nbre3kg*this.prix3kg)+(this.nbre6kg*this.prix6kg)+(this.nbre10kg*this.prix10kg))*this.freq2).toFixed(2)
        }else if (this.frequence === "1 fois par mois"){
          return (((this.nbre3kg*this.prix3kg)+(this.nbre6kg*this.prix6kg)+(this.nbre10kg*this.prix10kg))*this.freq3).toFixed(2)
        } else {
          return "Erreur: Aucune Fréquence valable trouvé"
        }
      }
    },
  }
}
</script>

<style scoped>

</style>