<template>
  <v-container>
    <v-card
        class="mx-auto"
        max-width="344"
    >
      <v-card-text>
        <div>J'aurai besoin de combien de</div>
        <p class="text-h4 text--primary">
          Fruits Secs
        </p>
        <div>
          <v-text-field
              v-model="collaborateur"
              label="Nombre de collaborateurs"
              :rules="rules"
              hide-details="auto"
          ></v-text-field>
          <v-select
              v-model="frequence"
              :items="frequences"
              label="Fréquence"
          ></v-select>
        </div>
      </v-card-text>
      <v-card-actions>
        <p> Soit {{ equival500g }} bocaux de 500g</p>
      </v-card-actions>
      <v-card-actions>
        <p> Soit {{ equival700g }} bocaux de 700g</p>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions>
        <p> Total: {{ prixTotal }} €</p>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CalculatorFsecs",
  data: () => ({
    rules: [
      value => !!value || 'Required.',
    ],
    collaborateur: null,
    poidsMoy:35,
    frequences: [
      4.33,
      2.17,
      1],
    frequence: 0,
    cagette:0,
    pot700:0,
    pot500:0,
  }),
  computed:{
    calculCafe(){
      return Math.round(this.collaborateur * this.poidsMoy * this.frequence)
    },
    equival500g(){
      return Math.round((this.calculCafe%700)/500)
    },
    equival700g(){
      return Math.trunc(this.calculCafe/700)
    },
    prix500g(){
      return this.equival500g*14
    },
    prix700g(){
      return this.equival700g*19.60
    },
    prixTotal(){
      return this.prix500g+this.prix700g
    }
  }
}
</script>

<style scoped>

</style>